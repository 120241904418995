import { MatrixViewListBeforeEnter } from "@/new/auth/matrix-view-list.router-hook";
import get_title from "@/router/module/get_title";
import createMatrix from "./detail/create";
import editMatrix from "./detail/edit";
import accessSettings from "./detail/access-settings";
import mergeMatrices from "./merge";
import nomenclature from "./nomenclature";

const router = [
  {
    path: "/content/matrices",
    name: "matrices.list",
    component: () => import("@/new/pages/matrices"),
    meta: {
      title: get_title("Матрицы"),
      forVisitors: true,
    },
    beforeEnter: MatrixViewListBeforeEnter,
    children: [createMatrix, editMatrix, accessSettings, mergeMatrices],
  },
  nomenclature,
];

export default router;
