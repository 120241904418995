import axios from "@/axios";

const state = () => ({
  status: true,
  links: [],
  route: "/navigation/settings",
});

const mutations = {
  setStatus(state, { status }) {
    state.status = status;
  },
  Links(state, links) {
    state.links = links;
  },
};

const actions = {
  async setStatus({ commit }, { status }) {
    commit("setStatus", { status });
  },
  async getSettingsList({ commit, state }) {
    const { data } = await axios.get(`${state.route}`);
    if (data && data["result"] && data["result"]["navigation_items"]) {
      let arr = [];
      for (const item of data["result"]["navigation_items"]) {
        arr.push({ ...item });
      }
      commit("Links", arr);
    }
  },
  async clearLinks({ commit }) {
    commit("Links", []);
  }
};

const getters = {
  status: (state) => state.status,
  route: (state) => state.route,
  links: (state) => state.links,
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
