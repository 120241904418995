import axios from "@/axios";

const state = () => ({
  draft: {
    changes_count: 0,
    status: {},
  },
  history_list: [],
});

const mutations = {
  setDraft(state, draft) {
    state.draft = draft;
  },
  setHistoryList(state, list) {
    state.history_list = list;
  },
};

const actions = {
  // загружаем информацию о текущем черновике
  async fetch({ commit }) {
    const { data } = await axios("/drafts/current");
    commit("setDraft", data["result"]["draft"]);
  },
  async setHistoryList({ commit }) {
    const { data } = await axios("/drafts/history");
    commit("setHistoryList", data["result"]["items"]);
  },
};

const getters = {
  counter: (state) => state.draft.changes_count,
  draft: (state) => state.draft,
  history: (state) => state.history_list,
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
