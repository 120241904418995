import { useLocalStorage, createSharedComposable } from "@vueuse/core";
import { computed } from "vue";

const USER_TOKEN_KEY = "token";

export const getToken = () => localStorage.getItem(USER_TOKEN_KEY);
export const setToken = (token: string) =>
  token === undefined || token === null
    ? localStorage.removeItem(USER_TOKEN_KEY)
    : localStorage.setItem(USER_TOKEN_KEY, token);

const USER_REFRESH_TOKEN_KEY = "refresh-token";

export const getRefreshToken = () =>
  localStorage.getItem(USER_REFRESH_TOKEN_KEY);
export const setRefreshToken = (token?: string) =>
  token === undefined || token === null
    ? localStorage.removeItem(USER_REFRESH_TOKEN_KEY)
    : localStorage.setItem(USER_REFRESH_TOKEN_KEY, token);

const USER_TOKEN_BCKP_KEY = "token-backup";
export interface BackupedToken {
  token: string;
  refresh: string;
}
function composableBackupToken() {
  const storedToken = useLocalStorage(USER_TOKEN_BCKP_KEY, {
    token: null,
    refresh: null,
  });
  const tokenBackuped = computed(() => storedToken.value.token !== null);
  const cleanupTokenBackup = () => {
    storedToken.value = {
      token: null,
      refresh: null,
    };
  };
  const setupTokenBackup = () => {
    setToken(storedToken.value.token);
    setRefreshToken(storedToken.value.refresh);
  };
  const backupToken = () => {
    storedToken.value = {
      token: getToken(),
      refresh: getRefreshToken(),
    };
  };
  return {
    backupToken,
    tokenBackuped,
    setupTokenBackup,
    cleanupTokenBackup,
  };
}

export const useBackupToken = createSharedComposable(composableBackupToken);
