import get_title from "@/router/module/get_title";
import { MatrixOperationsRouterHook } from "@/new/auth/matrix-operations.router-hook";

export default {
  path: "/content/matrices/create",
  name: "matrices.create",
  component: () => import("@/new/pages/matrices/detail/create.vue"),
  meta: {
    title: get_title("Создание матрицы"),
    forVisitors: true,
  },
  beforeEnter: MatrixOperationsRouterHook,
};
