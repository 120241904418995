import { defineStore } from "pinia";
import { NomenclatureImportRecord } from "@/types";
import {
  handleErrorArray,
  handleResponseMessageArray,
} from "@/axios/response-handler";
import { ImportCancel } from "@/axios/product/import/import-cancel";
import { ImportStatusGet } from "@/axios/product/import/import-status.get";
import { ImportCreate } from "@/axios/product/import/import-create";

export enum ImportStatus {
  IN_PROCESS = "import processing",
  FINISHED = "import successful finished",
  POSTPROCESS = "post processing",
  CANCELED = "canceled",
  FALL_ERROR = "failed with error",
  AVAILABLE = "available",
}
export interface ImportProcessState {
  importLog: Array<NomenclatureImportRecord>;
  status: ImportStatus;
  progressValue?: number;
}

export const useProductImportStore = defineStore("ProductImportStore", {
  state: (): ImportProcessState => ({
    importLog: [],
    status: ImportStatus.AVAILABLE,
    progressValue: undefined,
  }),
  getters: {
    progress: (state) => state.progressValue ?? 0,
    process_exists: (state) => state.status !== ImportStatus.AVAILABLE,
    is_importing: (state) => state.status === ImportStatus.IN_PROCESS,
    is_post_process: (state) => state.status === ImportStatus.POSTPROCESS,
    is_imported: (state) => state.status === ImportStatus.IN_PROCESS,
    is_import_failed: (state) => state.status === ImportStatus.FALL_ERROR,
    is_import_canceled: (state) => state.status === ImportStatus.CANCELED,
  },
  actions: {
    pushStep({ log, progress }) {
      this.importLog = this.importLog.concat(log);
      this.progressValue = progress;
    },
    startPostProcess() {
      this.progressValue = 100;
      this.status = ImportStatus.POSTPROCESS;
    },
    finish() {
      this.progressValue = 100;
      this.status = ImportStatus.FINISHED;
    },
    cancel() {
      this.status = ImportStatus.CANCELED;
    },
    start() {
      this.status = ImportStatus.IN_PROCESS;
      this.progressValue = 0;
    },
    async updateImportStatus() {
      try {
        await ImportStatusGet();
        this.status = ImportStatus.IN_PROCESS;
      } catch (_e) {
        this.$reset();
      }
    },
    async requestImport(fileTable: File) {
      try {
        const response = await ImportCreate(fileTable);
        handleResponseMessageArray(response);
      } catch (e) {
        handleErrorArray(e);
      }
    },
    async requestImportCancel() {
      try {
        const response = await ImportCancel();
        handleResponseMessageArray(response);
      } catch (e) {
        handleErrorArray(e);
      }
    },
  },
});
