import get_title from "@/router/module/get_title";

export default {
  path: "/content/users/:userId/edit",
  name: "User-Edit",
  component: () => import("@/new/pages/users/edit.vue"),
  meta: {
    title: get_title("Редактировать пользователя"),
  },
};
