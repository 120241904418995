import { ErrorService } from "@/new/utils/error.service";
import { captureException } from "@sentry/vue";

export function Http500Handler({ error }) {
  if (process.env.NODE_ENV === "development") {
    console.warn("[axios]:", { ...error });
  }
  captureException(error);
  ErrorService(
    process.env.NODE_ENV === "development"
      ? "Ошибка сервера. Подробности в консоли"
      : "Что-то пошло не так.. Обратитесь в поддержку"
  );
}
