import Vue from "vue";
import Vuetify from "vuetify/lib";
import i18n from "./i18n";

Vue.use(Vuetify);
export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
  theme: {
    default: "light",
    themes: {
      light: {
        success: "#3c8a2e",
        error: "#cd202c",
        red: "#cd202c",
        blue: "#21314d",
        orange: "#e37222",
        primary: "#5b3427",
        primaryOpacity20: "#ded6d4",
        primary2: "#FFF",
        //primaryOld: "#ee6363",
        secondary: "#999",
        secondary2: "#EFEFEF",
        secondary3: "#333333",
        secondary4: "#8B8B8B",
        secondary5: "#a7a7a7",
        secondary6: "#eeeeee",
        secondary7: "#f3f3f3",
        secondary8: "#f9f9f9",
        placeholder1: "#bec2c8",
        placeholder2: "#9E9E9E",
        placeholder3: "#c1c1c1",
      },
    },
    options: { customProperties: true },
  },
});
