import axios from "@/axios";

const state = () => ({
  lang: {},
  arrLang: [],
  route: "/language",
});

const mutations = {
  setArrLang(state, { arr }) {
    state.arrLang = arr;
  },
  setLang(state, { lang }) {
    state.lang = lang;
  },
};

const actions = {
  async setArrLang({ commit, state }) {
    const { data } = await axios.get(`${state.route}`);
    if (data && data["result"] && Array.isArray(data["result"])) {
      commit("setArrLang", {
        arr: data["result"],
      });
      commit("setLang", {
        lang: data["result"][0],
      });
    }
  },
  setLang({ commit }, { lang }) {
    commit("setLang", { lang });
  },
};

const getters = {
  arrLang: (state) => state.arrLang,
  lang: (state) => state.lang,
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
