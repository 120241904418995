import backurl from "@/router/module/backurl";
import get_title from "@/router/module/get_title";
import validate_role from "@/router/module/validate_role";

import router from "@/router";

export default {
  path: "/login",
  name: "Login",
  component: () => import("@/new/pages/login.vue"),
  meta: {
    title: get_title("Авторизация"),
    forVisitors: true,
  },
  beforeEnter: (to, from, next) => {
    const options = {
      route: { to, from, next },
      variant: "login",
      redirectText:
        "Вы уже авторизованы. Если вы хотите войти в другой аккаунт, сначала выйдите из текущего",
    };
    validate_role(options);
    if (router.history._startLocation === "/login") {
      backurl(options, "/content/nomenclature");
    } else {
      backurl(options, router.history._startLocation);
    }
  },
};
