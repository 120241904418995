import validate_role from "@/router/module/validate_role";
import get_title from "@/router/module/get_title";

export default {
  path: "/content/synchronization",
  meta: {
    title: get_title("Синхронизация"),
  },
  component: () =>
    import(
      /* webpackChunkName: "sync" */ "@/new/pages/synchronization/index.vue"
    ),
  beforeEnter: (to, from, next) => {
    const options = {
      role: "synchronization.show-list",
      route: { to, from, next },
      callback: false,
      redirectText: "У вас нет доступа к разделу с синхронизацией!",
    };
    validate_role(options);
  },
};
