import axios, { RESPONSE_CODE } from "@/axios";
import { ResponseMessage, UserRole } from "@/types";

type RoleListResponse = ResponseMessage<Array<UserRole>>;
export async function RoleListGet(): Promise<Array<UserRole>> {
  let result = [];
  const { data } = await axios.get<RoleListResponse>("/roles");
  if (data && data.code === RESPONSE_CODE.OK && Array.isArray(data.result)) {
    result = data.result;
  }
  return result;
}
