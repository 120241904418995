import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

export default (Vue, router) => {
  if (process.env.VUE_APP_SENTRY_DSN) {
    Sentry.init({
      Vue,
      dsn: process.env.VUE_APP_SENTRY_DSN,
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracingOrigins: [process.env.VUE_APP_URL_API],
        }),
      ],
      ignoreErrors: ["401", "404"],
      normalizeDepth: 10,
      environment: process.env.VUE_APP_BRANCH,
      release: `${process.env.VUE_APP_SENTRY_PACKAGE}@${process.env.VUE_APP_VERSION}`,
      tracesSampleRate: 0.5,
      beforeBreadcrumb: function (breadcrumb, hint) {
        const excluded = ["mc.yandex.ru", "/_wdt/"];
        const findExcludedUrl = (entry) =>
          breadcrumb.data.url.indexOf(entry) !== -1;
        const breadcrumbExcluded = () => excluded.find(findExcludedUrl);
        const breadcrumbInfoLength = 5120;

        if (breadcrumb.category === "xhr" && !breadcrumbExcluded()) {
          breadcrumb.data.response = hint.xhr.responseText.slice(
            0,
            breadcrumbInfoLength
          );
          breadcrumb.data.request = hint.input.slice(0, breadcrumbInfoLength);
        }
        if (breadcrumb.category === "fetch" && !breadcrumbExcluded()) {
          breadcrumb.data.request = hint.input[1].body.slice(
            0,
            breadcrumbInfoLength
          );
        }

        return breadcrumb;
      },
    });
  }
};
