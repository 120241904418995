import axios, { RESPONSE_CODE } from "@/axios";
import { setRefreshToken, setToken } from "@/axios/local-storage";
import { LoginDto, ResponseMessage, UserToken } from "@/types";

type LoginResponse = ResponseMessage<
  | {
      accessToken: UserToken;
      refreshToken: UserToken;
    }
  | undefined
>;
export async function LoginPost(dto: LoginDto) {
  let result = undefined;
  const { data } = await axios.post<LoginResponse>("/auth/login", dto);
  if (data.code === RESPONSE_CODE.OK && data.message === "success") {
    if (
      !!data.result &&
      !!data.result.accessToken &&
      !!data.result.refreshToken
    ) {
      setToken(data.result.accessToken.token);
      setRefreshToken(data.result.refreshToken.token);
      result = data;
    } else {
      result = data;
      setToken(undefined);
      setRefreshToken(data.result.refreshToken.token);
      delete axios.defaults.headers.common["Authorization"];
    }
  } else {
    result = data;
  }
  return result;
}
