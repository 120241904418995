import Vue from "vue";
import VueRouter from "vue-router";
import Store from "@/store";

import { getToken } from "@/axios/local-storage";
import Main from "./routes/Main";
import Login from "@/new/router/login";

Vue.use(VueRouter);

const routes = [Main, Login];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  duplicateNavigationPolicy: "ignore",
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.name !== "Login" && !getToken()) {
    await Store.dispatch("auth/logout");
    next({ name: "Login" });
  } else {
    next();
  }
});

export default router;
