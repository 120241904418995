<template>
  <div class="contematrix" id="app">
    <router-view />
    <portal-target name="popup" />
  </div>
</template>

<script>
import socket from "@/axios/socket";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      socketConnect: false,
    };
  },
  computed: {
    ...mapGetters({ profile: "auth/profile" }),
  },
  watch: {
    profile: {
      async handler(value) {
        if (!this.socketConnect && value !== undefined) {
          await socket();
          this.socketConnect = !this.socketConnect;
        }
      },
      immediate: true,
    },
  },
};
</script>

<!--suppress CssUnusedSymbol -->
<style lang="scss">
:root {
  font-size: 10px;
  --time-multiplier: 1;
}

html {
  overflow-y: auto !important;
}

body {
  /*
      если в каком-то месте приложения случится так,
      что текст не будет стилизован, его размер составит 10px (root)
      установим базовое значение в 1.4rem (=> 14px),
      чтобы в подобном случае его можно было хотя бы прочитать
    */
  font-size: 1.4rem;

  /*
      этот фонт-стек уже определен в variables.scss, но он распространяется
      только на .v-application, поэтому продублируем его здесь
    */
  font-family: "LatoWeb", sans-serif;
}

.v-main {
  background: #fafafa;
}

.v-pagination > :first-child,
.v-pagination > :last-child {
  display: none;
}
.v-pagination__item {
  box-shadow: none !important;
  outline: none;
}
#app.contematrix {
  max-height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: stretch;
}
.v-date-picker-title__date,
.v-date-picker-header__value button {
  text-transform: capitalize;
  font-size: 14px;
}
</style>
