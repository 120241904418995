import get_title from "@/router/module/get_title";

export default {
  path: "/content/users/create",
  name: "User-Create",
  component: () => import("@/new/pages/users/create.vue"),
  meta: {
    title: get_title("Создать пользователя"),
  },
};
