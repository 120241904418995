import get_title from "@/router/module/get_title";
import { MatrixViewListBeforeEnter } from "@/new/auth/matrix-view-list.router-hook";

export default {
  path: "/content/matrices/:id/nomenclature",
  alias: "/content/matrices/nomenclature/:id",
  name: "matrices.nomenclature",
  component: () => import("@/new/pages/matrices/nomenclature"),
  meta: {
    title: get_title("Редактирование матрицы"),
    forVisitors: true,
  },
  beforeEnter: MatrixViewListBeforeEnter,
};
