import { NotificationService } from "@/new/utils/notification.service";
import { captureException } from "@sentry/vue";

export function HttpErrorHandler({ error }) {
  if (process.env.NODE_ENV === "development") {
    console.warn("[axios]:", { ...error });
  }
  captureException(error);
  NotificationService("Упс! Что то пошло не так!");
}
