import Store from "@/store";
import bus from "eventbusjs";

export default async (options) => {
  const permissions = Store.getters["auth/permissions"];

  if (options.variant === "login") {
    if (!permissions.length) {
      if (options.callback) options.callback();
      else options.route.next();
    } else {
      options.route.next("/");
      if (options.redirectText) {
        setTimeout(() => {
          bus.dispatch("notification", this, {
            text: options.redirectText,
          });
        }, 2000);
      }
    }
  } else {
    if (permissions.includes(options.role)) {
      if (options.callback) options.callback();
      else options.route.next();
    } else {
      options.route.next("/");
      if (options.redirectText) {
        setTimeout(() => {
          bus.dispatch("notification", this, {
            text: options.redirectText,
          });
        }, 2000);
      }
    }
  }
};
