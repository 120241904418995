const state = () => ({
  per_page: 10,
});

const mutations = {
  setPerPage(state, v) {
    state.per_page = v;
  },
};

export default {
  namespaced: true,
  mutations,
  state,
};
