import axios from "@/axios";
import { ProjectCategories, ResponseMessage } from "@/types";

export async function CategoryListGet() {
  let result = undefined;
  const { data } = await axios.get<ResponseMessage<ProjectCategories>>(
    "/categories/by-projects"
  );
  if (data && data.result) {
    result = data.result;
  }
  return result;
}
