import realtime from "@/realtime";
import { NotificationService } from "@/new/utils/notification.service";
import { ErrorService } from "@/new/utils/error.service";
import { useProductImportStore } from "@/new/modules/product/store/product-import.store";

enum ImportSocketEvents {
  error = "IMPORT_ERROR",
  start = "IMPORT_STARTS",
  update = "IMPORT_UPDATE",
  postprocessStart = "IMPORT_POST",
  postprocessError = "IMPORT_POST_ERROR",
  interupt = "IMPORT_INTERUPT",
  finish = "IMPORT_READY",
}

export const ProductImportProcess = () => {
  const store = useProductImportStore();
  realtime.setListener(ImportSocketEvents.error, ({ message }) => {
    ErrorService(message);
    store.cancel();
  });
  realtime.setListener(ImportSocketEvents.start, ({ message }) => {
    NotificationService(message);
    store.start();
  });
  realtime.setListener(
    ImportSocketEvents.update,
    (_placeholder, { progress, stepItems }) => {
      store.pushStep({
        progress,
        log: stepItems,
      });
    }
  );
  realtime.setListener(ImportSocketEvents.postprocessStart, ({ message }) => {
    NotificationService(message);
    store.startPostProcess();
  });
  realtime.setListener(ImportSocketEvents.postprocessError, ({ message }) => {
    ErrorService(message);
  });
  realtime.setListener(ImportSocketEvents.interupt, ({ message }) => {
    NotificationService(message);
    store.cancel();
  });
  realtime.setListener(ImportSocketEvents.finish, ({ message }) => {
    NotificationService(message);
    store.finish();
  });
};
