// noinspection JSUnusedGlobalSymbols
import io from "socket.io-client";

const realtime = {
  listeners: [],
  socketSource: undefined,
  clearAllListeners() {
    this.listeners = [];
  },
  clearListeners(event) {
    for (let priority = 0; priority < 10; priority++) {
      if (this.listeners[priority]) {
        this.listeners[priority][event] = [];
      }
    }
  },
  setListener(event, callback, priority = 0) {
    if (!this.listeners[priority]) {
      this.listeners[priority] = [];
    }
    if (!this.listeners[priority][event]) {
      this.listeners[priority][event] = [];
    }
    this.listeners[priority][event].push(callback);
    return this;
  },
  onMessage(event, data, message) {
    for (let priority = 0; priority < 10; priority++) {
      if (this.listeners[priority] && this.listeners[priority][event]) {
        for (let listener of this.listeners[priority][event]) {
          listener(message, data);
        }
      }
    }
  },
  disconnectSocket() {
    this.socketSource && this.socketSource.disconnect();
    this.clearAllListeners();
  },
};

export function connect(host, token, channels) {
  realtime.socketSource = io(host, { query: "token=" + token });
  const subscribeToChannel = function (channelName) {
    realtime.socketSource.on(channelName, (data) => {
      console.info("[ws] received " + channelName + ": ", data);
      realtime.onMessage(data.event, data.message, data);
    });
  };

  for (let channel of channels || []) {
    subscribeToChannel(channel);
  }
}

export default realtime;
