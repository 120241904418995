import Store from "@/store";

export const MainRouterHook = (to, from, next) => {
  //@TODO: Систематизировать авторизацию роутера
  if (!to.fullPath.includes("/content/matrices")) {
    const role = Store.getters["auth/role"];
    if (role) {
      const directlyMatrixUsers = ["admin_matrix", "manager_matrix"];
      if (directlyMatrixUsers.includes(role.slug)) {
        return next("/content/matrices");
      }
    }
  }
  next();
};
