import axios from "axios";
import Errors from "./errors";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { getToken } from "./local-storage";

export enum RESPONSE_CODE {
  OK = 200,
  SERVER_ERROR = 500,
  SERVER_TIMEOUT = 504,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  BAD_REQUEST = 400,
  NOT_FOUND = 404,
}

const AxiosInstance = () => {
  // инстанс аксиоса для обработки всех запросов кроме refresh_token
  const axiosApiInstance = axios.create({
    baseURL: process.env.VUE_APP_URL_API,
  });

  // перед каждым запросом перезаписываем акутальный token
  axiosApiInstance.interceptors.request.use(
    (config) => {
      const token = getToken();
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // инстанс аксиоса для обработок ошибок refresh_token
  const axiosApiRefreshInstance = axios.create({
    baseURL: process.env.VUE_APP_URL_API,
  });
  const refreshAuthLogic = async (failedRequest) =>
    typeof Errors[failedRequest.response.status] === "function"
      ? Errors[failedRequest.response.status]({
          instance: axiosApiRefreshInstance,
          failedRequest,
        })
      : undefined;
  createAuthRefreshInterceptor(axiosApiInstance, refreshAuthLogic, {
    statusCodes: [
      RESPONSE_CODE.UNAUTHORIZED,
      RESPONSE_CODE.FORBIDDEN,
      RESPONSE_CODE.SERVER_ERROR,
    ],
  }); // обрабатывает ошибку 401, обновляет токен

  return axiosApiInstance;
};

export default AxiosInstance();
