import axios from "@/axios";
import realtime, { connect } from "@/realtime";
import store from "@/store";
import { ProductImportProcess } from "./product-import-process";
import { useFilterBus } from "@/new/shared/filters";
export default async () => {
  try {
    const filterDataBus = useFilterBus();
    const { data } = await axios("/web-socket/get-token");
    connect(process.env.VUE_APP_WEBSOCKET_URL, data.result.token, [
      "drafts",
      `USER_CHANNEL_${store.getters["auth/profile"]["id"]}`,
    ]);

    realtime.setListener("DRAFTS_CURRENT_DRAFT_CHANGE", () => {
      store.dispatch("changes/fetch");
      store.dispatch("changes/setHistoryList");
    });
    realtime.setListener("FILTER", (data) => {
      if (data && data["message"]) {
        filterDataBus.emit(data["message"]);
      }
    });
    ProductImportProcess();
  } catch (e) {
    console.error(e);
  }
};
