import get_title from "@/router/module/get_title";
import validate_role from "@/router/module/validate_role";
import AttributePage from './attribute';
import LanguagePage from "./language";
import ProjectPage from "./project";
import ReferencePage from "./reference";

export default [
  {
    path: "/content/settings",
    name: "Settings",
    component: () => import("@/new/pages/settings/index.vue"),
    meta: {
      title: get_title("Настройки"),
      forVisitors: true,
    },
    beforeEnter: (to, from, next) => {
      const options = {
        role: "navigation_settings",
        route: { to, from, next },
        callback: false,
        redirectText: "У вас нет доступа к разделу с настройками!",
      };
      validate_role(options);
    },
  },
  AttributePage,
  LanguagePage,
  ProjectPage,
  ReferencePage,
];

