import axios from "@/axios";

export function ImportCreate(tableFile: File) {
  const f = new FormData();
  f.append("table", tableFile);
  return axios.post<Array<string>>("/products/import/nomenclature", f, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
