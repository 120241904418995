import { ErrorService } from "@/new/utils/error.service";
import { NotificationService } from "@/new/utils/notification.service";
import { AxiosError, AxiosResponse } from "axios";

const UNKNOWN_ERROR_CREATE = "Не известная ошибка, обратитесь к поддержке";
export const handleErrorArray = (e: AxiosError<Array<string>>) => {
  if (e.response && Array.isArray(e.response.data)) {
    for (const responseMessage of e.response.data) {
      ErrorService(responseMessage);
    }
  } else {
    ErrorService(UNKNOWN_ERROR_CREATE);
  }
};
export const handleResponseMessageArray = (
  response: AxiosResponse<Array<string>>
) => {
  if (response.data && Array.isArray(response.data)) {
    for (const responseMessage of response.data) {
      NotificationService(responseMessage);
    }
  }
};
