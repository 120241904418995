import { Http401Handler } from "./http-401-handler";
import { Http403Handler } from "./http-403-handler";
import { Http500Handler } from "./http-500-handler";
import { HttpErrorHandler } from "./http-error-handler";

const Errors = {
  401: Http401Handler,
  403: Http403Handler,
  500: Http500Handler,
  unknown: HttpErrorHandler,
};

export default Errors;
