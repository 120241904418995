import store from "@/store";
import router from "@/router";
import { getRefreshToken, getToken } from "@/axios/local-storage";
import { RefreshLoginPost } from "../user/auth/refresh-login.post";

export async function Http401Handler({ instance, failedRequest }) {
  const refresh_token = getRefreshToken() ?? "";
  try {
    const reponse = await RefreshLoginPost(refresh_token, instance);
    if (reponse) {
      failedRequest.response.config.headers[
        "Authorization"
      ] = `Bearer ${getToken()}`;
    }
    return Promise.resolve();
  } catch (e) {
    await store.dispatch("auth/logout");
    await router.push({ name: "Login" }).catch(() => null);
  }
  return Promise.reject();
}
