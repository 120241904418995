import get_title from "@/router/module/get_title";

export default {
  path: "/content/history",
  meta: {
    title: get_title("История"),
    forVisitors: true,
  },
  component: () => import("@/views/Content/History"),
};
