import validate_role from "@/router/module/validate_role";

export const MatrixViewListBeforeEnter = (to, from, next) => {
  const options = {
    role: "matrix.view-list",
    route: { to, from, next },
    callback: false,
    redirectText: "У вас нет доступа к разделу с матрицами!",
  };
  validate_role(options);
};
