import router from "@/router";
import { LoginPost } from "@/axios/user/auth/login.post";
import { setUser } from "@sentry/vue";
import { AuthorizeById } from "@/axios/user/auth/authorize-by-id";
import { ProfileGet } from "@/axios/user/profile/profile.get";
import { RESPONSE_CODE } from "@/axios";
import realtime from "@/realtime";
import {
  setRefreshToken,
  setToken,
  useBackupToken,
} from "@/axios/local-storage";
import { ErrorService } from "@/new/utils/error.service";
const state = () => ({
  dialog: false, // flag to determine whether to show account-settings-dialog
  profile: undefined,
  route: "/auth/login",
  temp_user: {
    token: false,
    refresh_token: false,
  },
  token: false,
  refresh_token: false,
  backurl: false,
  avatar_default: require("@/assets/img/no-avatar.png"),
});

const mutations = {
  setProfile(state, data) {
    state.profile = data;
  },
  setToken(state, { token }) {
    state.token = token;
  },
  setBackurl(state, url) {
    if (url === "/login") {
      state.backurl = "/";
    } else {
      state.backurl = url;
    }
  },
  setRefreshToken(state, token) {
    state.refresh_token = token;
  },
  setTempUser(state, temp) {
    state.temp_user = temp;
  },
  setAvatar(state, data) {
    if (data) {
      state.profile.avatar = {};
      state.profile.avatar.url = data.url;
      state.profile.avatar.id = data.id;
    } else {
      state.profile.avatar = null;
    }
  },
  cleanupTempUser(state) {
    state.temp_user = {
      token: false,
      refresh_token: false,
    };
  },
};

const actions = {
  async login({ dispatch }, dto) {
    const data = await LoginPost(dto);
    if (data && data.message === "success") {
      await dispatch("getProfile");
    }
    return data;
  },
  async getProfile({ commit }) {
    let result = true;
    try {
      const { data } = await ProfileGet();
      if (data && data["code"] === RESPONSE_CODE.OK) {
        setUser(data["result"]["user"]);
        commit("setProfile", data["result"]["user"]);
      }
    } catch (e) {
      result = false;
    }
    return result;
  },
  logout({ commit }) {
    realtime.disconnectSocket();
    commit("setProfile", undefined);
    commit("cleanupTempUser");
    setToken(undefined);
    setRefreshToken(undefined);
    const { cleanupTokenBackup } = useBackupToken();
    cleanupTokenBackup();
  },
  setAvatar({ commit }, data) {
    commit("setAvatar", data);
  },
  async setToken({ commit }, { token }) {
    commit("setToken", { token });
  },
  setRefreshToken({ commit }, { token }) {
    commit("setRefreshToken", token);
  },
  async changeUser({ dispatch }) {
    await dispatch("getProfile");
    await dispatch("menu/getMenuList", null, { root: true });
  },
  setBackurl({ commit }, url) {
    commit("setBackurl", url);
  },
  async loginOtherUser({ dispatch }, id) {
    const { data } = await AuthorizeById(id);
    if (data && data["code"] === 200 && data["result"]) {
      setToken(data["result"]["accessToken"]["token"]);
      setRefreshToken(data["result"]["refreshToken"]["token"]);
      await dispatch("changeUser");
    } else {
      ErrorService("Не удалось авторизироваться за другого пользователя!");
    }
  },
};

const getters = {
  avatar: (state) => {
    let result = state.avatar_default;
    if (state.profile && state.profile.avatar && state.profile.avatar.url) {
      result = state.profile.avatar.url;
    }
    return result;
  },
  avatar_id: (state) => {
    let result = null;
    if (state.profile && state.profile.avatar && state.profile.avatar.id) {
      result = state.profile.avatar.id;
    }
    return result;
  },
  profile: (state) => state.profile,
  fullname: (state) =>
    state.profile
      ? [
          state.profile.last_name,
          state.profile.first_name,
          state.profile.middle_name,
        ].join(" ")
      : undefined,
  nicename: (state) => {
    let result = undefined;
    if (state.profile && state.profile.first_name) {
      result = `${state.profile.first_name} ${state.profile.last_name.slice(
        0,
        1
      )}.`;
    }
    return result;
  },
  permissions: (state) => {
    let result = [];
    if (state.profile && state.profile.role) {
      result = state.profile.role.permissions.map((el) => el["slug"]);
    }
    return result;
  },
  role: (state) =>
    state.profile && state.profile.role ? state.profile.role : undefined,
  route: (state) => state.route,
  token: (state) => state.token,
  refreshToken: (state) => state.refresh_token,
  temp_user: (state) => state.temp_user,
  backurl: (state) => state.backurl,
  grantedGlobalSearch: (state, getters) =>
    getters.role
      ? ![
          "product_manager",
          "1c_client",
          "accountant",
          "admin_matrix",
          "manager_matrix",
        ].includes(getters.role.slug)
      : false,
  grantedFileImport: (state, getters) =>
    getters.permissions.includes("product-list.import-file"),
  grantedUserEdit: (state, getters) =>
    getters.permissions.includes("user.show-general"),
  grantedUserAuth: (state, getters) =>
    getters.permissions.includes("user.auth"),
  grantedUserCreate: (state, getters) =>
    getters.permissions.includes("user.create"),
  grantedMatrixEdit: (state, getters) => (matrix) => {
    return getters.role &&
      getters.role &&
      getters.role.slug === "manager_matrix"
      ? matrix.author &&
          getters.profile &&
          matrix.author.id === getters.profile.id
      : getters.permissions.includes("matrix.operation");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
