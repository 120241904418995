import { PermissionExistsStore } from "@/new/auth/permission-exists.store";

export default function NomenclatureModelRouterHook(permission = undefined) {
  return (to, from, next) => {
    if (permission && PermissionExistsStore(permission)) {
      next();
    } else {
      next({ path: "/" });
    }
  };
}
