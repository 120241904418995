import { CategoryListGet } from "@/axios/category/category-list.get";
import { CategoryListPut } from "@/axios/category/category-list.put";

const state = () => ({
  loaded: false,
  categoryByProject: {},
});
const mutations = {
  Loaded(state, status) {
    state.loaded = status;
  },
  CategoryByProject(state, data) {
    state.categoryByProject = data
  },
};

const actions = {
  async fetch({ commit }) {
    const data = await CategoryListGet();
    if (data) {
      for (const project of data) {
        commit("CategoryByProject", data);
      }
      commit("Loaded", true);
    }
  },
  async update({ dispatch }, data) {
    await CategoryListPut(data.platform, data.list);
    return dispatch("fetch");
  },
};

const getters = {
  categories: (state) => {
    return { ...state };
  },
  loaded: (state) => state.loaded,
  categoryByProject: (state) => state.categoryByProject,
};

export default {
  namespaced: true,
  getters,
  state,
  actions,
  mutations,
};
