import get_title from "@/router/module/get_title";
import { MatrixOperationsRouterHook } from "@/new/auth/matrix-operations.router-hook";

export default {
  path: "/content/matrices/merge",
  name: "matrices.merge",
  component: () => import("@/new/pages/matrices/merge.vue"),
  meta: {
    title: get_title("Объединение матриц"),
  },
  beforeEnter: MatrixOperationsRouterHook,
};
