import validate_role from "@/router/module/validate_role";
import get_title from "@/router/module/get_title";

export default {
  path: "/content/catalog",
  name: "Catalog",
  component: () => import("@/views/Content/Catalog.vue"),
  meta: {
    title: get_title("Каталог"),
    forVisitors: true,
  },
  beforeEnter: (to, from, next) => {
    const options = {
      role: "models.show-list",
      route: { to, from, next },
      callback: false,
      redirectText: "У вас нет доступа к разделу с каталогом!",
    };
    validate_role(options);
  },
};
