import get_title from "@/router/module/get_title";
import validate_role from "@/router/module/validate_role";
import createLanguage from "./detail/create";
import editLanguage from "./detail/edit";

export default {
  path: "/content/settings/language",
  name: "Settings-Language",
  component: () => import("@/new/pages/settings/language/index.vue"),
  meta: {
    title: get_title("Редактирование языков"),
    forVisitors: true,
  },
  beforeEnter: (to, from, next) => {
    const options = {
      role: "settings_language",
      route: { to, from, next },
      callback: false,
      redirectText: "У вас нет доступа к разделу с настройками!",
    };
    validate_role(options);
  },
  children: [createLanguage, editLanguage],
};
