import store from "@/store";

export const migratePersistedStorage = (version) => {
  if (localStorage.getItem("version") !== version) {
    store.dispatch("auth/logout");
    localStorage.clear();
    setTimeout(() => {
      localStorage.setItem("version", version);
    });
  }
};
