import axios from "@/axios";
import { setRefreshToken, setToken } from "@/axios/local-storage";
import { AxiosInstance } from "axios";

interface RefreshTokenResponse {
  token: string;
  refresh_token: string;
}

export async function RefreshLoginPost(
  refresh_token: string,
  instance?: AxiosInstance
) {
  const client = instance ?? axios;
  let result = false;
  const { data } = await client.post<RefreshTokenResponse>(
    "/auth/token/refresh",
    { refresh_token }
  );
  if (!!data.token && !!data.refresh_token) {
    setToken(data.token);
    setRefreshToken(data.refresh_token);
    result = true;
  } else {
    setToken(undefined);
    setRefreshToken(undefined);
    delete axios.defaults.headers.common["Authorization"];
  }
  return result;
}
