import axios from "@/axios";

const state = () => ({
  status: true,
  hoverMenu: true,
  miniMenuSize: 56,
  defaultMenuSize: 236,
  links: [],
  route: "/navigation",
});

const mutations = {
  setStatus(state, { status }) {
    state.status = status;
  },
  setHoverMenu(state, { hover }) {
    state.hoverMenu = hover;
  },
  Links(state, links) {
    state.links = links;
  },
};

const actions = {
  async setStatus({ commit }, { status }) {
    commit("setStatus", { status });
  },
  async setHoverMenu({ commit }, { hover }) {
    commit("setHoverMenu", { hover });
  },
  async getMenuList({ commit, state }) {
    const { data } = await axios.get(`${state.route}`);
    if (data && data["result"] && data["result"]["navigation_items"]) {
      let arr = [];
      for (const item of data["result"]["navigation_items"]) {
        if (item.link === "/content/nomenclature") {
          arr.push({ ...item, link: "/content/nomenclature" });
        } else {
          arr.push({ ...item });
        }
      }
      commit("Links", arr);
    }
  },
};

const getters = {
  status: (state) => state.status,
  hoverMenu: (state) => state.hoverMenu,
  miniMenuSize: (state) => state.miniMenuSize,
  defaultMenuSize: (state) => state.defaultMenuSize,
  route: (state) => state.route,
  links: (state) => state.links,
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
