import validate_role from "@/router/module/validate_role";

export const MatrixOperationsRouterHook = (to, from, next) => {
  const options = {
    role: "matrix.operation",
    route: { to, from, next },
    callback: false,
    redirectText: "У вас нет доступа к разделу с матрицами!",
  };
  validate_role(options);
};
