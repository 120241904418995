import get_title from "@/router/module/get_title";
import NomenclatureModelRouterHook from "@/new/auth/nomenclature-model.router-hook";

export default {
  path: "/content/nomenclature/:productId/price",
  name: "product-edit__price",
  meta: {
    title: get_title("Товар | Цены"),
    forVisitors: true,
  },
  component: () => import("@/new/pages/nomenclature/edit/price.vue"),
  beforeEnter: NomenclatureModelRouterHook("product.show-prices"),
};
