import { RoleListGet } from "@/axios/role/role-list.get";

const state = () => ({
  roles: [],
});

const mutations = {
  setRoles(state, roles) {
    state.roles = roles;
  },
};

const actions = {
  async fetchRoles({ commit }) {
    const { data } = await RoleListGet();
    if (data) {
      commit("setRoles", data["result"]);
    }
  },
};

const getters = {
  roles: (state) => state.roles,
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
