import validate_role from "@/router/module/validate_role";
import get_title from "@/router/module/get_title";
import createPage from "./create";
import editPage from "./edit";

export default {
  path: "/content/users",
  name: "Users",
  component: () => import("@/new/pages/users/index.vue"),
  meta: {
    title: get_title("Пользователи"),
    forVisitors: true,
  },
  beforeEnter: (to, from, next) => {
    const options = {
      role: "users.show-list",
      route: { to, from, next },
      callback: false,
      redirectText: "У вас нет доступа к разделу с пользователями!",
    };
    validate_role(options);
  },
  children: [createPage, editPage],
};
